<template>
  <div class='alarm'>
    <el-tabs v-model="activeName" type="border-card" @tab-click="handleClick">
      <el-tab-pane label="当前" name="first">
        <present :activeName="activeName" />
      </el-tab-pane>
      <el-tab-pane label="历史" name="second">
        <history :activeName="activeName" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import Present from './components/present.vue'
import History from './components/history.vue'
export default {
  components: {
    Present,
    History
  },
  data () {
    return {
      activeName: 'first'
    };
  },
  created () {

  },
  mounted () {

  },
  methods: {
    handleClick (tab, event) {
      console.log(tab, event);
    }
  }
};
</script>

<style scoped lang="scss">
</style>
