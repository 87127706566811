<template>
  <div class='present'>
    <div class="action">
      <div>
        <span>位置选择：</span>
        <el-select v-model="argumentsList.schoolId" placeholder="请选择" @change="schoolChange" size="small" clearable>
          <el-option v-for="item in cascadedlocation" :key="item.id" :label="item.label" :value="item.id">
          </el-option>
        </el-select>
        <span>设备编号：</span>
        <el-input v-model="argumentsList.deviceId" placeholder="请输入设备编号" @input="schoolChange" size="small" />
      </div>
    </div>
    <el-alert type="info" show-icon>
      <template slot="title">
        列表记录总计：<span>{{total}}</span> 条
      </template>
    </el-alert>
    <el-table :data="tableData" style="width: 100%" :header-cell-style="headStyle" :cell-style="rowStyle" v-if="activeName === 'first'" size="mini">
      <el-table-column prop="deviceid" label="设备编号">
      </el-table-column>
      <el-table-column prop="school" label="单位">
      </el-table-column>
      <el-table-column prop="location" label="位置">
      </el-table-column>
      <el-table-column prop="flow" label="当前流量(t)">
      </el-table-column>
      <el-table-column prop="alertthreshold" label="额定流量">
      </el-table-column>
      <el-table-column prop="process" label="报警级别">
      </el-table-column>
      <el-table-column prop="currentStatus" label="处理阶段">
      </el-table-column>
      <el-table-column prop="phone" label="责任人" width="70">
        <template slot-scope="{row}">
          <el-button type="text" @click="viewContact(row)">查看</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="eventtime" label="报警时间">
        <template slot-scope="{row}">
          {{row.eventtime?$moment(row.eventtime).format('YYYY-MM-DD HH:mm:ss'):'暂无'}}
        </template>
      </el-table-column>
      <el-table-column prop="supplier" label="距今天数">
        <template slot-scope="{row}">
          {{$moment().diff(row.gmtcreate, 'day')>0?$moment().diff(row.gmtcreate, 'day'):1}}
        </template>
      </el-table-column>
      <el-table-column prop="action" label="操作">
        <template slot-scope="{row}">
          <el-button v-if='row.remark === "待处理" || row.remark === "审核失败"' type="text" @click="eventDispose(row)" style="color:red">事件处理</el-button>
          <el-button v-else-if="row.remark === '待审核'" type="text" @click="eventCheck(row)" style="color:green">事件审核</el-button>
          <span v-else-if="row.remark === '已审核'">已完成</span>
        </template>
      </el-table-column>
    </el-table>
    <base-pagination :total="total" layout="prev, pager, next" :page.sync="queryParams.pageNum" :limit.sync="queryParams.pageSize" @pagination="getList" />
    <!-- 联系人弹框 -->
    <el-dialog title="联系人信息" :visible.sync="contactVisible" width="20%" :lock-scroll="false" top="40vh">
      <p class="item">姓名：<span>{{contact.name}}</span></p>
      <p class="item">电话：<span>{{contact.phone}}</span></p>
    </el-dialog>
    <!-- 事件处理弹框 -->
    <el-dialog title="滤芯更换事件处理" :visible.sync="eventDisposeDialog" width="30%" @close="eventDisposeClose" :lock-scroll="false">
      <el-form :model="disposeForm" :rules="rules" ref="disposeForm" :hide-required-asterisk="true">
        <el-form-item label="滤芯更换时间：" prop="date">
          <el-date-picker type="date" placeholder="请选择滤芯更换时间" v-model="disposeForm.date" style="width: 100%;"></el-date-picker>
        </el-form-item>
        <el-form-item label="滤芯更换阶段：" prop="type">
          <el-select disabled v-model="disposeForm.type" placeholder="请选择滤芯更换阶段" style="width: 100%;">
            <el-option label="处理完成" :value="1"></el-option>
            <!-- <el-option label="区域二" value="beijing"></el-option> -->
          </el-select>
        </el-form-item>
        <el-form-item label="滤芯更换图片：" prop="img">
          <base-upload accept="image/jpeg,image/jpeg,image/png" listType="picture" :file.sync="disposeForm.img" :isShowTip="false" :fileLimit="1" uploadText="上传滤芯更换图片（只能上传一张图片）" @upload="upload" @remove='remove' />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="eventDisposeClose">取 消</el-button>
        <el-button type="primary" @click="disposeSumbit('disposeForm')">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 事件审核弹框 -->
    <el-dialog title="滤芯更换事件审核" :visible.sync="checkDialog" width="50%" :lock-scroll="false">
      <el-row>
        <el-col :span="12" class="imgbox">
          <img :src="`https://watersupervision.terabits.cn:9091/querymanagement/processquery/changefilterimg/get?access_token=${token}&img=${checkForm.imgId}`" alt="">
        </el-col>
        <el-col :span="12">
          <el-form :model="checkForm" :rules="checkRules" ref="checkForm" :hide-required-asterisk="true">
            <el-form-item label="滤芯更换时间：">
              <el-input v-model="checkForm.gmtupdate" disabled></el-input>
            </el-form-item>
            <el-form-item label="事件处理人：" v-if="checkForm.wxCommitResPeople">
              <el-input v-model="checkForm.wxCommitResPeople.name" disabled></el-input>
            </el-form-item>
            <el-form-item label="处理结果：" prop="result">
              <el-select v-model="checkForm.result" placeholder="请选择处理结果" style="width: 100%;">
                <el-option label="合格" value="1"></el-option>
                <el-option label="不合格" value="0"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <div slot="footer" class="dialog-footer">
        <el-button @click="checkDialog = false">取 消</el-button>
        <el-button type="primary" @click="checkSumbit('checkForm')">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getAlarmList, getImgId, updateAlarm } from '@/api'
export default {
  props: {
    activeName: {
      type: String
    }
  },
  data () {
    return {
      // 联系人弹窗
      contactVisible: false,
      contact: {},
      // 表格数据
      tableData: [],
      queryParams: {
        pageSize: 10,
        pageNum: 1
      },
      totalList: [],
      total: 0,
      argumentsList: {
        eventtype: 1,
        provinceId: 31,
        cityId: 383,
        districtId: 3230,
        schoolId: null,
        result: 0,
        deviceId: ''
      },
      // 事件处理弹框
      eventDisposeDialog: false,
      disposeForm: {
        date: null,
        type: 1,
        img: null
      },
      rules: {
        date: [
          { type: 'date', required: true, message: '请选择滤芯更换时间', trigger: 'change' }
        ],
        region: [
          { required: true, message: '请选择滤芯更换阶段', trigger: 'change' }
        ],
        img: [
          { required: true, message: '请上传滤芯更换图片', trigger: 'change' }
        ]
      },
      // 审核弹窗
      checkDialog: false,
      checkForm: {
        date: '2022-12-5',
        person: 'xxx',
        result: ''
      },
      checkRules: {
        result: [
          { required: true, message: '请选择处理结果', trigger: 'change' }
        ]
      },
      // 处理参数
      updataParams: {
        type: 1,
        imgId: '',
        eventId: '',
        changeDate: ''
      },
      fileList: [],
      imgList: [],
      alarmMsg: {}
      // checkMsg: {}
    };
  },
  created () {
    this.init()
  },
  mounted () {
    console.log('this.token :>> ', this.token);
  },
  computed: {
    ...mapGetters(['cascadedlocation', 'token', 'realname'])
  },
  methods: {
    // 获取报警列表
    async init () {
      const res = await getAlarmList(this.argumentsList)
      this.totalList = res
      this.total = res.length
      this.getList()
    },
    // 分页操作
    getList () {
      this.tableData = []
      let start = (this.queryParams.pageNum - 1) * this.queryParams.pageSize
      let end = ''
      if (this.queryParams.pageNum * this.queryParams.pageSize > this.total) {
        end = this.total
      }
      else {
        end = this.queryParams.pageSize * this.queryParams.pageNum
      }
      for (let i = start; i < end; i++) {
        this.tableData.push(this.totalList[i])
      }
      // console.log('tableData :>> ', this.tableData);
    },
    // 筛选
    schoolChange () {
      this.queryParams.pageNum = 1
      this.init()
    },
    // 查看联系人
    viewContact (val) {
      // console.log('val :>> ', val);
      this.contact = val.detail[0].resPeople[0]
      this.contactVisible = true
    },
    // 表格居中
    headStyle () {
      return "text-align:center"
    },
    rowStyle () {
      return "text-align:center"
    },
    // 事件处理
    eventDispose (val) {
      this.updataParams.eventId = val.id
      this.alarmMsg = val
      this.eventDisposeDialog = true
    },
    // 上传图片存
    upload (data) {
      this.imgList.push(data)
    },
    // 删除图片
    remove (data) {
      this.imgList = this.imgList.filter(el => el.file.name !== data.name)
    },
    // 事件处理确认
    disposeSumbit (formName) {
      // console.log('this. :>> ', this.disposeForm);
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          await this.checkImgId()
          this.updataParams.changeDate = this.$moment(this.disposeForm.date).format('YYYY-MM-DD')
          await updateAlarm(this.updataParams)
          this.eventDisposeClose()
          this.$message.success('事件处理完成')
          this.init()
          this.$add(
            {
              userName: this.realname,
              module: '报警管理',
              content: `${this.realname}处理了 ${this.alarmMsg.school} 位于 ${this.alarmMsg.location} 的设备报警事件，设备编号为: ${this.alarmMsg.deviceid}`,
              type: 5,
              platform: 8,
              operateTime: this.$moment().format('YYYY-MM-DD HH:mm:ss')
            }
          )
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 重置表单
    eventDisposeClose () {
      this.$refs.disposeForm.resetFields()
      this.fileList = []
      this.eventDisposeDialog = false
    },
    // 获取图片id
    async checkImgId () {
      let formdata = new FormData()
      formdata.append('file', this.imgList[0].file, this.imgList[0].file.name)
      this.updataParams.imgId = await getImgId(this.token, formdata)
    },
    // 事件审核
    eventCheck (row) {
      this.checkForm = row
      this.checkForm.result = ''
      this.checkDialog = true
    },
    // 事件审核确认
    checkSumbit (formName) {
      // console.log('this. :>> ', this.disposeForm);
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          // this.updataParams.changeDate = this.$moment(this.disposeForm.date).format('YYYY-MM-DD')
          const params = {
            eventId: this.checkForm.id,
            result: this.checkForm.result,
            type: 2
          }
          await updateAlarm(params)
          this.eventCheckClose()
          this.$message.success('事件审核成功')
          this.init()
          this.$add(
            {
              userName: this.realname,
              module: '报警管理',
              content: `${this.realname}进行了 ${this.checkForm.school} 的滤芯更换事件审核，设备编号为: ${this.checkForm.deviceid}`,
              type: 5,
              platform: 8,
              operateTime: this.$moment().format('YYYY-MM-DD HH:mm:ss')
            }
          )
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 重置表单
    eventCheckClose () {
      this.$refs.checkForm.resetFields()
      this.checkDialog = false
    }
  }
};
</script>

<style scoped lang="scss">
.present {
  .action {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
    > div {
      span {
        font-size: 16px;
        margin-left: 10px;
      }
      .el-select,
      .el-input {
        width: 300px;
      }
    }
  }
  ::v-deep .el-dialog {
    .el-dialog__body {
      .item {
        margin-bottom: 10px;
        font-size: 14px;
        span {
          font-size: 16px;
        }
      }
      .el-row {
        .imgbox {
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 100%;
          }
          img {
            // position: absolute;
            width: 240px;
            // height: 100px;
            // transform: scale(0.5);
          }
        }
      }
    }
  }
}
</style>
