<template>
  <div class='history'>
    <div class="action">
      <div>
        <span>位置选择：</span>
        <el-select clearable v-model="argumentsList.schoolId" placeholder="请选择" size="small" @change="filterChange">
          <el-option v-for="item in cascadedlocation" :key="item.id" :label="item.label" :value="item.id">
          </el-option>
        </el-select>
        <span>时间选择：</span>
        <el-date-picker @change="dateChange" size="small" v-model="date" type="datetimerange" :picker-options="pickerOptions" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" align="right">
        </el-date-picker>
        <!-- <el-input v-model="date" placeholder="请输入设备编号" size="small" /> -->
        <span>设备编号：</span>
        <el-input v-model="argumentsList.deviceId" placeholder="请输入设备编号" size="small" @input="filterChange" clearable />
      </div>
      <!-- <el-button type="primary" size="small" @click="actionUnit('add')">新 建</el-button> -->
    </div>
    <el-alert type="info" show-icon>
      <template slot="title">
        列表记录总计：<span>{{total}}</span> 条
      </template>
    </el-alert>
    <el-table :data="tableData" style="width: 100%" :header-cell-style="headStyle" :cell-style="rowStyle" v-if="activeName === 'second'" size="mini">
      <el-table-column prop="deviceid" label="设备编号">
      </el-table-column>
      <el-table-column prop="school" label="单位">
      </el-table-column>
      <el-table-column prop="location" label="位置">
      </el-table-column>
      <el-table-column prop="alertthreshold" label="额定流量">
      </el-table-column>
      <el-table-column prop="flow" label="报警流量">
      </el-table-column>
      <el-table-column prop="gmtcreate" label="报警时间">
        <template slot-scope="{row}">
          {{$moment(row.gmtcreate).format('YYYY-MM-DD HH:mm:ss')}}
        </template>
      </el-table-column>
      <el-table-column prop="time" label="处理时间">
        <template slot-scope="{row}">
          {{$moment(row.detail[row.detail.length-1].gmtcreate).format('YYYY-MM-DD HH:mm:ss')}}
        </template>
      </el-table-column>
      <el-table-column prop="address" label="处理天数">
        <template slot-scope="{row}">
          {{$moment(row.detail[row.detail.length-1].gmtcreate).diff(row.gmtcreate, 'day')>0?$moment(row.detail[row.detail.length-1].gmtcreate).diff(row.gmtcreate, 'day'):1}}
        </template>
      </el-table-column>
      <el-table-column prop="action" label="详情">
        <template slot-scope="{row}">
          <el-button type="text" @click="checkDetail(row)">详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <base-pagination :total="total" layout="prev, pager, next" :page.sync="queryParams.pageNum" :limit.sync="queryParams.pageSize" @pagination="getList" />

    <!-- 查看历史事件处理详情 -->
    <el-dialog title="事件处理阶段详情" :visible.sync="detailDialog" width="40%" :lock-scroll="false">
      <el-table :data="detailData" size="small" :header-cell-style="headStyle" :cell-style="rowStyle">
        <el-table-column property="content" label="事件阶段"></el-table-column>
        <el-table-column property="gmtcreate" label="操作时间"></el-table-column>
        <el-table-column property="name" label="操作员">
          <template slot-scope="{row}">
            {{row.resPeople[0].name}}
          </template>
        </el-table-column>
        <el-table-column property="phone" label="联系方式">
          <template slot-scope="{row}">
            {{row.resPeople[0].phone}}
          </template>
        </el-table-column>
      </el-table>
      <!-- <p class="imgbox">
        <img :src="`https://watersupervision.terabits.cn:9091/querymanagement/processquery/changefilterimg/get?access_token=${token}&img=${imgId}`" alt="">
      </p> -->
      <el-dialog class="imgdialog" width="30%" title="查看图片" :visible.sync="imgVisible" append-to-body :lock-scroll="false">
        <p class="imgbox">
          <img :src="`https://watersupervision.terabits.cn:9091/querymanagement/processquery/changefilterimg/get?access_token=${token}&img=${imgId}`" alt="">
        </p>
      </el-dialog>
      <div slot="footer" class="dialog-footer">
        <!-- <el-button @click="detailDialog = false">取 消</el-button> -->
        <el-button type="primary" @click="imgVisible = true">查 看 图 片</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getAlarmList } from '@/api'
import { mapGetters } from 'vuex'

export default {
  props: {
    activeName: {
      type: String
    }
  },
  data () {
    return {
      // 时间
      date: [],
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }],
        disabledDate (time) {
          return time.getTime() > Date.now()
        }
      },
      // 表格数据
      tableData: [],
      queryParams: {
        pageSize: 10,
        pageNum: 1
      },
      totalList: [],
      total: 0,
      argumentsList: {
        eventtype: 1,
        provinceId: 31,
        cityId: 383,
        districtId: 3230,
        schoolId: null,
        result: 1,
        beginTime: this.$moment().subtract(2, 'month').format('YYYY-MM-DD HH:mm:ss'),
        endTime: this.$moment().format('YYYY-MM-DD HH:mm:ss'),
        deviceId: ''
      },
      // 查看详情弹框
      detailDialog: false,
      detailData: [],
      imgId: '',
      imgVisible: false
    };
  },
  created () {
    this.init()
  },
  mounted () {

  },
  computed: {
    ...mapGetters(['cascadedlocation', 'token'])
  },
  methods: {
    // 获取报警列表
    async init () {
      const res = await getAlarmList(this.argumentsList)
      this.totalList = res
      this.date = [this.argumentsList.beginTime, this.argumentsList.endTime]
      this.total = res.length
      this.getList()
    },
    // 分页操作
    getList () {
      this.tableData = []
      let start = (this.queryParams.pageNum - 1) * this.queryParams.pageSize
      let end = ''
      if (this.queryParams.pageNum * this.queryParams.pageSize > this.total) {
        end = this.total
      }
      else {
        end = this.queryParams.pageSize * this.queryParams.pageNum
      }
      for (let i = start; i < end; i++) {
        this.tableData.push(this.totalList[i])
      }
      // console.log('tableData :>> ', this.tableData);
    },
    // 表格居中
    headStyle () {
      return "text-align:center"
    },
    rowStyle () {
      return "text-align:center"
    },
    // 查看详情
    checkDetail (val) {
      this.detailData = val.detail
      this.imgId = val.imgId
      this.detailDialog = true
    },
    // 筛选数据
    filterChange () {
      this.queryParams.pageNum = 1
      this.init()
    },
    // 日期选择
    dateChange (val) {
      if (val) {
        this.argumentsList.beginTime = this.$moment(val[0]).format('YYYY-MM-DD HH:mm:ss')
        this.argumentsList.endTime = this.$moment(val[1]).format('YYYY-MM-DD HH:mm:ss')
      } else {
        this.argumentsList.beginTime = ''
        this.argumentsList.endTime = ''
      }
      this.filterChange()
    }
  }
};
</script>

<style scoped lang="scss">
.history {
  .action {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    > div {
      span {
        font-size: 16px;
        margin-left: 10px;
      }
      .el-select,
      .el-input {
        width: 300px;
      }
    }
  }
}
</style>
